.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #333232;
    color: white;
  
    .loading-spinner {
      position: relative;
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
  
      .spinner-sector {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 8px solid transparent;
        mix-blend-mode: overlay;
      }
  
      .spinner-sector-primary {
        border-top-color: #646cff;
        animation: rotate 1.5s ease-in-out infinite;
      }
  
      .spinner-sector-secondary {
        border-left-color: #ff647c;
        animation: rotate 2s ease-in infinite;
      }
  
      .spinner-sector-tertiary {
        border-right-color: #64ff8d;
        animation: rotate 2.5s ease-out infinite;
      }
    }
  
    .loading-text {
      font-size: 1.2rem;
      font-weight: 500;
    }
  
    @keyframes rotate {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  }